import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "@/views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/views/Auth.vue")
  },
  {
    path: "/qiscus",
    component: () => import("@/views/Qiscus.vue")
  },
  {
    path: "/client",
    name: "Client",
    component: () =>
      import(/* webpackChunkName: "client" */ "../views/Client.vue")
  },
  {
    path: "/staf",
    component: () => import("@/views/Staf.vue"),
    children: [
      {
        path: "/",
        component: () => import("@/components/chat/BaseView.vue")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
