import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

// import js
import "bootstrap";
import VueSweetalert2 from "vue-sweetalert2";

// import css
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "@/assets/css/rw-custom.css";

Vue.use(VueSweetalert2);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
